import React from 'react'
import { Typography, Stack, List, ListItem, ListItemText, Button } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation} from 'react-i18next';


const is_past = (date) => {
  const today = new Date().toJSON().slice(0, 10);
  
  return date <= today;
}

const OrderDetails = (props) => {
  const {order, orderHandler} = props;
  const { t } = useTranslation();

  return (
    <>
    <Stack direction="row" onClick={() => orderHandler(null)} alignItems="center" sx={{ cursor: 'pointer'}}>
      <ArrowBackIosIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
      <Typography component="body1" sx={{ flexGrow: 1 }}>{t("meta.back_to_list")}</Typography>
    </Stack>
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem divider={true} secondaryAction={<FiberManualRecordIcon fontSize="small" color={is_past(order.production_date) ? "success" : "error"} />}>
        <ListItemText primary={t("orders.production_date")} secondary={t("meta.date", {date: new Date(order.production_date)})} />
      </ListItem>
      <ListItem divider={true} secondaryAction={<FiberManualRecordIcon fontSize="small" color={is_past(order.loading_date) ? "success" : "error"} />}>
        <ListItemText primary={t("orders.loading_date")} secondary={t("meta.date", {date: new Date(order.loading_date)})} />
      </ListItem>
      <ListItem divider={true} secondaryAction={<FiberManualRecordIcon fontSize="small" color={is_past(order.arrival_date) ? "success" : "error"} />}>
        <ListItemText primary={t("orders.arrival_date")} secondary={t("meta.date", {date: new Date(order.arrival_date)})} />
      </ListItem>
      <ListItem divider={true}>
        <ListItemText primary={t("orders.forwarder")} secondary={order.forwarder} />
      </ListItem>
      <ListItem divider={true} secondaryAction={<FiberManualRecordIcon fontSize="small" color={order['30_percents'] ? "success" : "error"} />}>
        <ListItemText primary={t("orders.down_payment")} />
      </ListItem>
      <ListItem divider={true} secondaryAction={<FiberManualRecordIcon fontSize="small" color={order['70_percents'] ? "success" : "error"} />}>
        <ListItemText primary={t("orders.balance")} />
      </ListItem>
      <ListItem divider={true} secondaryAction={<FiberManualRecordIcon fontSize="small" color={order.documents_sent ? "success" : "error"} />}>
        <ListItemText primary={t("orders.documents_sent")} secondary={t("orders.documents_sent_secondary")} />
      </ListItem>
      <ListItem divider={true}>
        <ListItemText primary={t("orders.comments")} secondary={order.comment} />
      </ListItem>
      {order.fileUrl && (
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdfIcon />}
            fullWidth
            href={order.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("orders.download_pi")}
          </Button>
        </ListItem>
      )}
    </List>
    </>
  )
}

export default OrderDetails;
